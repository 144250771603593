import request from 'utils/request';

const api = {
  SOURCE_LIST: '/api/source/overall/list',
  SOURCE_ADD: '/api/source/overall/add',
  SOURCE_UPDATE: '/api/source/overall/update',
  SOURCE_UPDATE_BUSINESS: '/api/source/overall/updateBusiness',
  SOURCE_UPDATE_BUSINESSTYPE: '/api/source/overall/updateBusinessType',
  SOURCE_UPDATE_ACCOUNTMANAGER: '/api/source/overall/updateAccountManager',
  SOURCE_UPDATE_RATE: '/api/source/overall/updateRate',
};
export function getSourceList(query) {
  return request({
    url: api.SOURCE_LIST,
    method: 'get',
    params: query,
  });
}
export function insertSource(data) {
  return request({
    url: api.SOURCE_ADD,
    method: 'post',
    data,
  });
}
export function updateSource(data) {
  return request({
    url: api.SOURCE_UPDATE,
    method: 'put',
    data,
  });
}

export function updateBusiness(data) {
  return request({
    url: api.SOURCE_UPDATE_BUSINESS,
    method: 'post',
    data,
  });
}

export function updateBusinessType(data) {
  return request({
    url: api.SOURCE_UPDATE_BUSINESSTYPE,
    method: 'post',
    data,
  });
}
export function updateAccountManager(data) {
  return request({
    url: api.SOURCE_UPDATE_ACCOUNTMANAGER,
    method: 'post',
    data,
  });
}

export function updateSourceRate(data) {
  return request({
    url: api.SOURCE_UPDATE_RATE,
    method: 'post',
    data,
  });
}
